const ruBreadcrumbsMapper = (path) => {
    return urls[path];
};

const templates = 'routes.templates';
const edit = 'routes.edit';
const create = 'routes.create';
const pages = 'routes.pages';
const letters = 'routes.letters';
const directory = 'routes.constructorDirectory';
const corpClients = 'routes.corp_clients';
const coordinators = 'routes.coordinators';
const baseAddresses = 'routes.baseAddresses';
const corpUsers = 'routes.corp_users';
const areas = 'routes.areas';

const urls = {
    '/constructor/projects': [
        {ru: 'routes.projects', link: ''},
    ],
    '/constructor/templates': [
        {ru: templates, link: ''}
    ],
    '/constructor/templates/edit/': [
        {ru: templates, link: '/constructor/templates'},
        {ru: create, link: ''}
    ],
    '/constructor/templates/edit/:id': [
        {ru: templates, link: '/constructor/templates'},
        {ru: edit, link: ''}
    ],
    '/constructor/pages': [
        {ru: pages, link: ''},
    ],
    '/constructor/pages/edit/': [
        {ru: pages, link: '/constructor/pages'},
        {ru: create, link: ''}
    ],
    '/constructor/pages/edit/:id': [
        {ru: pages, link: '/constructor/pages'},
        {ru: edit, link: ''}
    ],
    '/constructor/letters': [
        {ru: letters, link: ''},
    ],
    '/constructor/letters/edit/': [
        {ru: letters, link: '/constructor/letters'},
        {ru: create, link: ''}
    ],
    '/constructor/letters/edit/:id': [
        {ru: letters, link: '/constructor/letters'},
        {ru: edit, link: ''}
    ],
    '/constructor/files': [
        {ru: 'routes.files', link: ''},
    ],
    '/constructor/directory': [
        {ru: directory, link: ''},
    ],
    '/constructor/directory/edit/': [
        {ru: directory, link: '/constructor/directory'},
        {ru: create, link: ''},
    ],
    '/constructor/directory/edit/:id': [
        {ru: directory, link: '/constructor/directory'},
        {ru: edit, link: ''},
    ],
    '/constructor/corp-clients': [
        {ru: corpClients, link: ''},
    ],
    '/constructor/corp-clients/edit/': [
        {ru: corpClients, link: '/constructor/corp-clients'},
        {ru: create, link: ''},
    ],
    '/constructor/corp-clients/edit/:id': [
        {ru: corpClients, link: '/constructor/corp-clients'},
        {ru: edit, link: ''},
    ],
    '/constructor/coordinators': [
        {ru: coordinators, link: ''},
    ],
    '/constructor/coordinators/edit/': [
        {ru: coordinators, link: '/constructor/coordinators'},
        {ru: create, link: ''},
    ],
    '/constructor/coordinators/edit/:id': [
        {ru: coordinators, link: '/constructor/coordinators'},
        {ru: edit, link: ''},
    ],
    '/constructor/base_addresses': [
        {ru: baseAddresses, link: ''},
    ],
    '/constructor/base_addresses/edit/': [
        {ru: baseAddresses, link: '/constructor/base_addresses'},
        {ru: create, link: ''},
    ],
    '/constructor/base_addresses/edit/:id': [
        {ru: baseAddresses, link: '/constructor/base_addresses'},
        {ru: edit, link: ''},
    ],
    '/constructor/users': [
        {ru: corpUsers, link: ''},
    ],
    '/constructor/users/edit/': [
        {ru: corpUsers, link: '/constructor/users'},
        {ru: create, link: ''},
    ],
    '/constructor/users/edit/:id': [
        {ru: corpUsers, link: '/constructor/users'},
        {ru: edit, link: ''},
    ],
    '/constructor/areas': [
        {ru: areas, link: ''},
    ],
    '/constructor/areas/edit/': [
        {ru: areas, link: '/constructor/areas'},
        {ru: create, link: ''},
    ],
    '/constructor/areas/edit/:id': [
        {ru: areas, link: '/constructor/areas'},
        {ru: edit, link: ''},
    ],
    '/constructor/areas': [
        {ru: areas, link: ''},
    ],
    '/constructor/areas/edit/': [
        {ru: areas, link: '/constructor/areas'},
        {ru: create, link: ''},
    ],
    '/constructor/areas/edit/:id': [
        {ru: areas, link: '/constructor/areas'},
        {ru: edit, link: ''},
    ],
};

export default ruBreadcrumbsMapper;